import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'
import DefaultButton from '@/calendesk/sections/section/components/DefaultButton.vue'
import { mapActions } from 'vuex'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import {
  email,
  lowercase,
  maxChars,
  minChars,
  number,
  required,
  specialChar,
  uppercase
} from '@/calendesk/forms/validators'
import { plainToClass } from 'class-transformer'
import GdprFieldData from '@/calendesk/models/GdprFieldData'
import GdprFieldUserData from '@/calendesk/models/GdprFieldUserData'
import CompanyDataActions from '@/calendesk/sections/section/shared/mixins/CompanyDataActions'

export default mixins(Section, CompanyDataActions).extend({
  components: { DefaultButton },
  data () {
    return {
      isLoading: false,
      signUpForm: null,
      email: null as string | null,
      password: null as string | null,
      showPassword: false,
      name: null as string | null,
      surname: null as string | null,
      phone: null as string | null,
      selectedGdprFields: [] as GdprFieldUserData[],
      rules: {
        required,
        email,
        minChars,
        maxChars,
        lowercase,
        uppercase,
        number,
        specialChar
      }
    }
  },
  created () {
    this.companyVatIsoPrefix = (this.appConfiguration.companyCountryIsoCode || this.appConfiguration.language).toUpperCase()
    this.companyCountryIsoCode = (this.appConfiguration.companyCountryIsoCode || this.appConfiguration.language).toLowerCase()
  },
  computed: {
    getAvailableGdprFields (): GdprFieldData[] {
      if (this.data.configuration.wb_gdpr_fields__gdpr_fields__) {
        return plainToClass(GdprFieldData, this.data.configuration.wb_gdpr_fields__gdpr_fields__ as GdprFieldData[])
      }

      return []
    }
  },
  methods: {
    ...mapActions({
      signUpUserAndStoreSession: 'user/signUpUserAndStoreSession'
    }),
    gdprFieldChanged (element: GdprFieldData, selected: boolean) {
      const found = this.selectedGdprFields.find((field) => (field.name === element.name && field.label === element.label))

      if (found) {
        const index = this.selectedGdprFields.indexOf(found)
        this.selectedGdprFields.splice(index, 1)
      } else {
        const newElement = new GdprFieldUserData(element.name, element.label, element.required, selected)
        this.selectedGdprFields.push(newElement)
      }
    },
    async handleSignUp () {
      (this.$refs.SignUpForm as any).validate()

      if (!this.signUpForm) {
        errorNotification('form_is_invalid', null, false)
        return
      }

      this.isLoading = true

      const payload: Record<string, any> = {
        name: this.name,
        surname: this.surname,
        email: this.email,
        password: this.password
      }

      if (this.phone) {
        payload.default_phone = this.phone
      }

      if (this.selectedGdprFields.length > 0) {
        payload.gdpr_fields = JSON.stringify(this.selectedGdprFields)
      }

      if (this.showBillingData) {
        payload.default_address = {
          name: this.companyName,
          street: this.companyStreet,
          postal_code: this.companyPostalCode,
          city: this.companyCity,
          country_iso_code: this.companyCountryIsoCode,
          vat_iso_prefix: this.companyVatIsoPrefix,
          tax_number: this.companyTaxNumber
        }
      }

      try {
        await this.signUpUserAndStoreSession(payload)

        if (this.data.configuration.wb_redirect_after_signup__page_id__) {
          this.redirectToPageUuid(this.data.configuration.wb_redirect_after_signup__page_id__)
        } else {
          this.redirectToMainPage()
        }
      } catch (error) {
        if (
          error &&
          error.message &&
          error.message === 'EXTRA_EMAIL_VERIFICATION_FAILED'
        ) {
          errorNotification('verification_invalid_error_notification', error, false)
        } else if ((error.response.data && error.response.data.errors && error.response.data.errors.email)) {
          errorNotification('email_is_taken', error, false)
        } else {
          errorNotification(null, error)
        }
      }

      this.isLoading = false
    }
  }
})
