




























































































































































































































import mixins from 'vue-typed-mixins'
import Signup from '@/calendesk/sections/section/static/signup/mixins/Signup'
import CPhoneInput from '@/views/components/CPhoneInput.vue'
import CCountryAutocomplete from '@/components/CCountryAutocomplete.vue'

export default mixins(Signup).extend({
  name: 'Signup',
  components: { CCountryAutocomplete, CPhoneInput }
})
